import React, { useEffect, useState } from 'react';
import back from "../../../../../assets/Recruitment/back.svg"
import { Popover, Spin, Table, Modal, Row, Col } from 'antd';
import dayjs from 'dayjs';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import ToolTip from '../../../../../component/ToolTip/ToolTip';
import view from "../../../../../assets/view.png";
import request from '../../../../../utils/axios';
import { toast } from 'react-toastify';
import './BulkApproveReject.scss';
import desktopStyle from "../../../../PayRoll/Admin/ReimbursementFullView/ReimbursementFullView.module.scss";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import activeDocumentImg from "../../../../../assets/activeDocumentImg.svg";
import activeDownload from "../../../../../assets/activeDownload.png";

function BulkApproveReject() {
    const [detailsModel, setDetailsModel] = useState(false);
    const [selectedDetailsData, setSelectedDetailsData] = useState([]);
    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [employeeLeaveDetailList, setEmployeeLeaveDetailList] = useState([]);
    const [dataLength, setDataLength] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [selectedSelectiveRowKeys, setSelectedSelectiveRowKeys] = useState([]);
    const [selectiveDataList, setSelectiveDataList] = useState([]);
    const [loadingApprove, setApproveLoading] = useState(false)
    const [loadingReject, setRejectLoading] = useState(false)
    const navigate = useNavigate();
    const rowSelection = {
        selectedRowKeys: selectedSelectiveRowKeys.filter(key =>
            selectiveDataList.some(item => item.key === key)
        ),
        onChange: (selectedRowKeys) => {
            const newSelectedRowKeys = [
                ...selectedSelectiveRowKeys.filter(key =>
                    !selectiveDataList.some(item => item.key === key)
                ),
                ...selectedRowKeys
            ];
            setSelectedSelectiveRowKeys(newSelectedRowKeys);
        }
    };
    const [taskID, setTaskId] = useState() 

        let currentIntervalId; // Keep track of the current interval ID
    
        const startPooling = (id) => {
            if (currentIntervalId) {
                clearInterval(currentIntervalId); // Clear any previous interval
            }
            currentIntervalId = setInterval(async () => {
                try {
                    const response = await request({
                        method: "get",
                        url: `leave/celery/task/status?id=${id}`,
                    });
    
                    if (response.status != "PENDING") { // Report generation complete
                        clearInterval(currentIntervalId); // Stop pooling
                        currentIntervalId = null;
                        fetchEmployeeLeaveDetail(1, []);
                        setApproveLoading(false)
                        setRejectLoading(false)
                        if(response.status == "SUCCESS"){
                            toast.success("Bulk Action success!", {
                                position: "bottom-left",
                            }); 
                        }else if(response.status == "FAILURE"){
                            toast.error("Bulk Action failed!", {
                                position: "bottom-left",
                            });
                        }
                    }
                } catch (err) {
                    console.log("Error checking report status: ", err);
                }
            }, 3000); // Poll every 3 seconds
        };
    
    const bulkUpload = async(isTrue)=>{
        try{
            if(isTrue === true){
                setApproveLoading(true)
            }else{
                setRejectLoading(true)
            }
            const response = await request({
                url: `leave/admin/leave-applications/bulk-action`,
                method: "post",
                data: {
                    "leave_application_ids" : selectedSelectiveRowKeys,
                    "is_approved": isTrue
                }
            });
            if(response){
                setTaskId(response.task_id)
                startPooling(response.task_id)
            }

        }catch(error){
            console.log(error)
        }
    }
    useEffect(()=>{
        console.log("selectedSelectiveRowKeys...",selectedSelectiveRowKeys)
    },[selectedSelectiveRowKeys])

    const employeeLeavePopUp = (record) => {
        return (
            <div>
                <div style={{ display: "flex", gap: "10px" }}>
                    {record?.leave_dates?.map((item, index) => {
                        return (
                            <>
                                <span>
                                    {item.leave_for === "full_day" ? (
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                color: "white",
                                                padding: "3px",
                                                borderRadius: "50%",
                                                background: "#008080",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        >
                                            {dayjs(item.date).format("DD")}
                                        </div>
                                    ) : record.from_second_half_start_date ? (
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                color: "black",
                                                padding: "3px",
                                                borderRadius: "50%",
                                                background:
                                                    "linear-gradient(to left, #008080, #008080 50%, #DDDDDD 50%)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        >
                                            {dayjs(item.date).format("DD")}
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                fontSize: "12px",
                                                color: "black",
                                                padding: "3px",
                                                borderRadius: "50%",
                                                background:
                                                    "linear-gradient(to right, #008080, #008080 50%, #DDDDDD 50%)",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                width: "25px",
                                                height: "25px",
                                            }}
                                        >
                                            {dayjs(item.date).format("DD")}
                                        </div>
                                    )}
                                </span>
                            </>
                        );
                    })}
                </div>
            </div>
        );
    };

    const employeeLeaveColumn = [
        {
            title: "Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "No. Of Leaves",
            dataIndex: "leave_day",
            key: "leave_day",
            render: (text, record) => (
                <span>
                    {text}
                    <Popover
                        placement="top"
                        title="Leaves"
                        content={employeeLeavePopUp(record)}
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </span>
            ),
        },
        {
            title: "Leave Type",
            dataIndex: "leave_type",
            key: "leave_type",
        },
        {
            title: "Leave From",
            dataIndex: "start_date",
            key: "start_date",
        },
        {
            title: "Leave To",
            dataIndex: "end_date",
            key: "end_date",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
                <>
                    <button className={`${text}LeaveButton`}>{text}</button>
                    <ToolTip title="View">
                        <img src={view} alt="view" width={18} height={18} style={{ marginLeft: "15px", cursor: "pointer" }}
                            onClick={() => {
                                setDetailsModel(true)
                                setSelectedDetailsData(record)
                            }}

                        />
                    </ToolTip>

                </>

            ),
        },
    ];

    const fetchEmployeeLeaveDetail = async (pageNo, dataList) => {
        const current = pageNo || page;
        try {
            const response = await request({
                method: "get",
                url: `leave/admin/bulk-approval/leave-application?page=${current}&page_size=${15}`,
            });
            console.log("aaaaaaaaaaaaaaaaaa", response.pagination_data.data);
            setEmployeeLeaveDetailList(response.pagination_data.data);

            if (pageNo) {
                setSelectiveDataList([
                    ...dataList,
                    ...response?.pagination_data?.data
                ])
                setItems([...dataList, ...response?.pagination_data?.data]);

            } else {
                setItems([...items, ...response?.pagination_data?.data]);
                setSelectiveDataList([
                    ...selectiveDataList,
                    ...response?.pagination_data?.data
                ])

            }
            setDataLength(response?.pagination_data?.total);

            setPage(current + 1);

            if (response?.pagination_data?.next === null) {
                setHasMore(false);
                return;
            }
            return response
        } catch (err) {
            console.log(err);
        }
    };


    useEffect(() => {
        fetchEmployeeLeaveDetail(1, []);
    }, [])

    return (
        <div style={{
            padding: "20px", overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
        }} id='scrollaleDiv'>
            <p onClick={() => navigate('/admin/leave/2')} style={{ cursor: "pointer" }}
            ><img src={back} alt="back" /> Back</p>
            <div style={{ padding: "20px", background: "white", borderRadius: "5px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", margin: "15px 0px" }}>
                    <h2>Select Employees for Bulk Leave Action</h2>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button onClick={()=>{bulkUpload(true)}}
                        className='approveButton'>
                        {loadingApprove ? (
                            <Spin
                                indicator={<LoadingOutlined spin style={{ color: "white" }} />}
                            />
                        ) : (
                                "Approve"
                        )}
                        </button>
                        <button 
                        disabled={loadingApprove? true: false}
                        onClick={()=>{bulkUpload(false)}} 
                        className='rejectButton'
                        >
                        {
                            loadingReject? (
                                <Spin
                                    indicator={<LoadingOutlined spin style={{ color: "white" }} />}
                                />
                            ):(
                                "Reject"
                            )
                        }
                        </button>
                    </div>
                </div>
                <div>
                    <InfiniteScroll
                        dataLength={dataLength - (items.length)}
                        next={fetchEmployeeLeaveDetail}
                        hasMore={hasMore}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        loader={
                            <p style={{ textAlign: 'center' }}>
                                <b>Loading...</b>
                            </p>
                        }
                        scrollableTarget="scrollaleDiv"
                    // height="72vh"
                    // className={styles.customScrollbar}
                    >
                        <Table
                            columns={employeeLeaveColumn}
                            dataSource={selectiveDataList}
                            rowSelection={rowSelection}
                            pagination={false}
                            sticky={{ offsetHeader: 0 }}
                        // handleRowClick={() => { }}
                        />
                    </InfiniteScroll>
                </div>
            </div>
        <Modal
        centered
        open={detailsModel}
        onOk={() => setDetailsModel(false)}
        onCancel={() => setDetailsModel(false)}
        width={1000}
        footer={null}
      >
        <p className={desktopStyle.reimbursementModelTitle}>Employee Leaves Detail</p>
        <Row gutter={[32, 12]}>
          <Col span={11}>
            <div className={desktopStyle.reimbursementModelKeyValueContainer}>
              <p className={desktopStyle.reimbursementModelKey}>Name</p>
              <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.employee_name}</p>
            </div>
            <div className={desktopStyle.reimbursementModelKeyValueContainer}>
              <p className={desktopStyle.reimbursementModelKey}>Leave From</p>
              <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.start_date}</p>
            </div>
            <div className={desktopStyle.reimbursementModelKeyValueContainer}>
              <p className={desktopStyle.reimbursementModelKey}>Leave To</p>
              <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.end_date}</p>
            </div>
            <div className={desktopStyle.reimbursementModelKeyValueContainer}>
              <p className={desktopStyle.reimbursementModelKey}>No of Leaves</p>
              <p className={desktopStyle.reimbursementModelValue}>
                {selectedDetailsData?.leave_day}
                <Popover
                  placement="top"
                  title="Leaves"
                  content={employeeLeavePopUp(selectedDetailsData)}
                >
                  <InfoCircleOutlined />
                </Popover>
              </p>
            </div>
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <div className={desktopStyle.reimbursementModelKeyValueContainer}>
              <p className={desktopStyle.reimbursementModelKey}>Status</p>
              <button className={`${selectedDetailsData?.status}LeaveButton`}>{selectedDetailsData?.status}</button>
            </div>
          </Col>
          <Col span={24}>
            <p className={desktopStyle.reimbursementModelKey}>Description</p>
            <div style={{ border: "1px solid #DDDDDD", background: "#F8F8F8", padding: "10px" }}>
              <p className={desktopStyle.reimbursementModelValue}>{selectedDetailsData?.reason}</p>
            </div>
          </Col>
          {
            selectedDetailsData?.document_details?.length > 0 &&
            <Col span={24}>
              <p className={desktopStyle.reimbursementModelKey}>Download documents</p>


              {
                selectedDetailsData?.document_details?.map((item, index) => {
                  return (
                    <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "space-between", padding: "10px", background: "#F8F8F8", width: "50%", marginBottom: "10px" }}>
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <img src={activeDocumentImg} alt="activeDocumentImg" />
                        <p style={{ margin: "0px", color: "#505050", fontWeight: "600", fontSize: "16px" }}>{item?.file_name}</p>
                      </div>
                      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                        <img src={view} alt="view" width={18} height={18}
                          onClick={() => {
                            window.open(item?.url)
                          }}
                        />
                        {/* download  pdf*/}

                        <a href={item.url} target="_blank" download>
                          <img src={activeDownload} alt="activeDownload" width={18} height={18}
                            onClick={async () => {

                              try {
                                const response = await fetch(item.url);
                                const blob = await response.blob();
                                saveAs(blob, `${item.file_name}.pdf`);
                              } catch (error) {
                                console.error('Error downloading PDF:', error);
                              }
                            }}
                          />
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </Col>
          }
        </Row>
      </Modal>
        </div>
    )
}

export default BulkApproveReject