// DynamicTable.js
import React, { useState, useEffect } from 'react';
import { Pagination, Table } from 'antd';

const DynamicTable = ({ fetchData, columns, handleRowClick, pageSize, showPagination, reload }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 5, // Number of items per page
        total: 0,
    });

    const handleTableChange = async (pagination, filters, sorter) => {
        try {
            const pager = { ...pagination };
            pager.current = pagination.current;

            setPagination(pager);
            setLoading(true);

            // Fetch data from the API using the provided fetchData function
            const result = await fetchData(
                pagination.current,
                pageSize
            );

            setData(result?.pagination_data?.data);

            setPagination({
                ...pagination,
                total: result?.pagination_data?.total,// Update total count of items
                pageSize: pageSize
            });

            setLoading(false);
        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        handleTableChange(pagination);
    }, [pagination.current, reload]);


    const renderPagination = () => {
        return false
    };

    const paginationConfig = {
        current: pagination.current,
        pageSize: pageSize,
        total: pagination.total,
        onChange: (page, pageSize) => {
            setPagination({
                ...pagination,
                current: page
            });
        },
    };


    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                pagination={renderPagination()}
                // loading={loading}
                onChange={handleTableChange}
                onRow={record => ({
                    onClick: () => {
                        handleRowClick(record);
                    }
                })}
            />
            {
                showPagination && <center>
                    <br />
                    <br />
                    <Pagination
                        {...paginationConfig}
                        showTotal={(total, range) =>
                            `${range[0]}-${range[1]} of ${total} items`
                        }
                        cellFontSize={16}
                    />
                </center>
            }

        </>
    );
};

export default DynamicTable;