import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    org: '',
    personalDetails: {
        employee_name: '',
        personal_email: null,
        contact: null,
        address: null,
        dob: null,
        gender: null,
        marital_status: null,
        anniversary: null,
        father_name: null,
        bank_name: null,
        ifsc_code: null,
        account_number: null,
        payment_mode: null,
        pan_no: null,
        uan_no: null,
        esic_no: null
    },
    employmentDetails: {
        designation: '',
        reporting_manager: '',
        joining_date: '',
        employee_type: '',
        department: '',
        probation_period: '',
        work_location: '',
        notice_period: '',
        employement_number: '',
        empfunction: '',
        official_email: '',
        employee_code: '',
        grade: '',
        hrbp: '',
        office_location: ''
    },
    policies: {
        leave_policy: '',
        work_policy: '',
        salary_structure: ''
    },
    salaryDetails: {
        ctc: '',
        salaryBreakUp: null,
        salary_type: null,
        salary_show_type: null

    }
}
const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {
        setOrgDetails: (state, action) => {
            state.org = action.payload
        },
        setPersonalDetails: (state, action) => {
            const {
                employee_name,
                personal_email,
                contact,
                address,
                dob,
                gender,
                marital_status,
                anniversary,
                father_name,
                bank_name,
                ifsc_code,
                account_number,
                payment_mode,
                pan_no,
                uan_no,
                esic_no
            } = action.payload;

            state.personalDetails.employee_name = employee_name;
            state.personalDetails.personal_email = personal_email;
            state.personalDetails.contact = contact;
            state.personalDetails.address = address;
            state.personalDetails.dob = dob;
            state.personalDetails.gender = gender;
            state.personalDetails.marital_status = marital_status;
            state.personalDetails.anniversary = anniversary;
            state.personalDetails.father_name = father_name;
            state.personalDetails.bank_name = bank_name;
            state.personalDetails.ifsc_code = ifsc_code;
            state.personalDetails.account_number= account_number;
            state.personalDetails.payment_mode = payment_mode;
            state.personalDetails.pan_no = pan_no;
            state.personalDetails.uan_no = uan_no;
            state.personalDetails.esic_no = esic_no;

        },

        setEmploymentDetails: (state, action) => {
            const {
                designation,
                reporting_manager,
                joining_date,
                employee_type,
                department,
                probation_period,
                work_location,
                notice_period,
                employement_number,
                empfunction,
                official_email,
                employee_code,
                grade,
                hrbp,
                office_location,
            } = action.payload;

            state.employmentDetails.designation = designation;
            state.employmentDetails.reporting_manager = reporting_manager;
            state.employmentDetails.joining_date = joining_date;
            state.employmentDetails.employee_type = employee_type;
            state.employmentDetails.department = department;
            state.employmentDetails.probation_period = probation_period;
            state.employmentDetails.work_location = work_location;
            state.employmentDetails.notice_period = notice_period;
            state.employmentDetails.employement_number = employement_number;
            state.employmentDetails.empfunction = empfunction;
            state.employmentDetails.official_email = official_email.toLocaleLowerCase();
            state.employmentDetails.grade = grade;
            state.employmentDetails.hrbp = hrbp;
            state.employmentDetails.employee_code = employee_code;
            state.employmentDetails.office_location = office_location;

        },

        setPolicies: (state, action) => {
            const {
                leave_policy,
                work_policy,
                salary_structure
            } = action.payload;

            state.policies.leave_policy = leave_policy;
            state.policies.work_policy = work_policy;
            state.policies.salary_structure = salary_structure;
        },

        setSalaryDetails: (state, action) => {
            const {
               ctc,
               salaryBreakUp,
               salary_type,
               salary_show_type,
            } = action.payload;

            state.salaryDetails.ctc = ctc;
            state.salaryDetails.salaryBreakUp = salaryBreakUp;
            state.salaryDetails.salary_type = salary_type;
            state.salaryDetails.salary_show_type = salary_show_type;
        },


        resetEmployeeDetails: (state, action) => {
            state.org = initialState.org;
            state.personalDetails = initialState.personalDetails;
            state.employmentDetails = initialState.employmentDetails;
            state.policies = initialState.policies;
            state.salaryDetails = initialState.salaryDetails
        }
    },
})
export const { setOrgDetails, setPersonalDetails, setEmploymentDetails, setPolicies, setSalaryDetails, resetEmployeeDetails } = employeeSlice.actions;

export default employeeSlice.reducer;