import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react'
import request from '../../utils/axios';
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';




const ChangePasswordComponent = ({
    updatePasswordModal,
    setUpdatePasswordModal,
    employementNumber,
    apiEndPoint,
  }) => {
    const [updatePasswordForm] = Form.useForm();
    const [sendMailSuccessfullModel, setSendMailSuccessfullModel] = useState(false)
    const [passwordError, setPasswordError] = useState(null); // State to hold API error
    const [updatePasswordLoader,setUpdatePasswordLoader] = useState(false)


    
    const updatePassword = async (value) => {
      console.log(apiEndPoint)
      setUpdatePasswordLoader(true)
      try {


        // Request data is changing on the basis for apiEndPoint props 
        const requestData =
        apiEndPoint === "users/password/change"
          ? { ...value, employement_number: employementNumber } 
          : {...value}  //requestData for  `users/self-password/change`
  
        await request({
        method: "post",
        url: apiEndPoint,
        data: requestData,
        });
        
        toast.success("Password updated successfully!",{position:"bottom-left"});
        setUpdatePasswordModal(false);
        setUpdatePasswordLoader(false)
        setPasswordError(null);
        updatePasswordForm.resetFields();

        setSendMailSuccessfullModel(true);
      } catch (error) {
        setUpdatePasswordModal(true)
        setUpdatePasswordLoader(false)
        const errorMessage = error.response?.data?.message ;
            const errorMessages = Array.isArray(errorMessage) ? errorMessage : [errorMessage];
            setPasswordError(errorMessages);
      } 
    };


    return (
      <>
      <Modal
        centered
        open={updatePasswordModal}
        footer={null}
        onCancel={() => {
          updatePasswordForm.resetFields();
          setUpdatePasswordLoader(false)
          setPasswordError(null)
          setUpdatePasswordModal(false);
        }}
      >
        <p style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "10px" }}>
          Change Password
        </p>
        <Form
          layout="vertical"
          onFinish={updatePassword}
          form={updatePasswordForm}
          autoComplete="on"
        >
          <Form.Item
            label="New Password"
            name="new_password"
            style={passwordError? {marginBottom:0}:{}}
            rules={[
              {
                required: true,
                message: "Please enter your new password!",
            },
            ]}
          >
            <Input
              size="large"
              placeholder="Enter New Password"
              autoComplete="on"
              type="text"
              onChange={() => {
                if (passwordError) setPasswordError(null);
              }}
              
            />
          </Form.Item>
          {passwordError && passwordError?.length > 0 && (
            <div style={{ color: "red", padding: "10px", marginTop:0 }}>
              {passwordError.map((err, index) => (
                <p key={index} style={{ marginBottom: "10px" }}>{err}</p>
              ))}
            </div>
          )}
          <Button
            htmlType="submit"
            style={{ background: "#008080", color: "white" }}
            block
            className='commonBtn'
          >
            {
              updatePasswordLoader ? 
              <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />}/> 
              :"Submit"
            }
          </Button>
        </Form>
      </Modal>

     <Modal
        centered
        open={sendMailSuccessfullModel}
        footer={null}
        onOk={() => setSendMailSuccessfullModel(false)}
        onCancel={() => setSendMailSuccessfullModel(false)}
      >
        <center>
          <img
            src={
              process.env.REACT_APP_CDN
                ? process.env.REACT_APP_CDN
                : "/media/Attendance/forgotImg.svg"
            }
            alt="forgotImg"
          />
          <p>Password is successfully updated</p>
        </center>
      </Modal>
    </>
    );
  };
  
  export default ChangePasswordComponent;