import React, { useEffect, useState } from 'react';

import dashboardImg from '../assets/dashboard.svg';
import orgImg from '../assets/Org.svg';
import employeeImg from '../assets/employee.svg';
import leaveImg from '../assets/leave.svg';
import attendanceImg from '../assets/attendance.svg';
import payrollImg from '../assets/payroll.svg';
import profileImg from '../assets/profile.svg';
import settingImg from '../assets/setting.svg';
import jobImg from '../assets/job.svg';
import documentImg from '../assets/documentImg.svg';
import lms from '../assets/inactiveLms.png';
import feed from "../assets/feed.png";

import activeOrgImg from '../assets/activeOrgImg.svg';
import activeDashboardImg from '../assets/activeDashboardImg.svg';
import activeAttendanceImg from '../assets/activeAttendanceImg.svg';
import activeLeaveImg from '../assets/activeLeaveImg.svg';
import activeEmployeeImg from '../assets/activeEmployeeImg.svg';
import activePayrollImg from '../assets/activePayrollImg.svg';
import activeDocumentImg from '../assets/activeDocumentImg.svg';
import activeJobImg from '../assets/activeJobImg.svg';
import activeLms from '../assets/activeLms.png';
import activeTracking from '../assets/location.svg';
import { Link, useLocation } from 'react-router-dom';
import tracking from '../assets/tracking.png';
import activeFeed from "../assets/activeFeed.png";

import report from "../assets/reportInActive.png";
import activeReport from "../assets/reportActive.png";
import kioskActive from "../assets/kioskActive.png"
import kiosk from "../assets/kiosk.png"
const AdminMenuList = ({ onDataChange }) => {
  const [data, setData] = useState([]);
  const [activeItem, setActiveItem] = useState(null);
  let location = useLocation();
  const currentUrl = location.pathname;

  const handleItemClick = key => {
    setActiveItem(key);
  };

  useEffect(() => {
    const roleList = JSON.parse(localStorage.getItem('role_list')) || [];

    const adminMenuList = [
      {
        key: 'dashboard',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/dashboard.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeDashboardImg.svg",
        label: 'Dashboard',
        path: '/admin/dashboard',
      },
      {
        key: 'org',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/Org.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeOrgImg.svg",
        label: 'Org',
        path: '/admin/org',
      },
      {
        key: 'attendance',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/attendance.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeAttendanceImg.svg",
        label: 'Attendance',
        path: '/admin/attendance',
      },

      {
        key: 'leave',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/leave.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeLeaveImg.svg",
        label: 'Leave',
        path: '/admin/leave',
      },
      {
        key: 'payroll',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/payroll.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activePayrollImg.svg",
        label: 'Payroll',
        path: '/admin/payroll',
      },
      {
        key: 'employee',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/employee.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeEmployeeImg.svg",
        label: 'Employee',
        path: '/admin/employee',
      },
      {
        key: 'recruitment',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/job.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeJobImg.svg",
        label: 'Job',
        path: '/admin/recruitment',
      },
      {
        key: 'docs',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/documentImg.svg",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeDocumentImg.svg",
        label: 'Document',
        path: '/admin/docs',
      },
      {
        key: 'lms',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/inactiveLms.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeLms.png",
        label: 'LMS',
        path: '/admin/lms/list',
      },
      {
        key: 'tracking',
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/tracking.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : activeTracking,
        label: 'Tracking',
        path: '/admin/tracking',
      },
      {
        key: "feeds",
        image: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/feed.png",
        activeImage: process.env.REACT_APP_CDN ? process.env.REACT_APP_CDN : "/media/activeFeed.png",
        label: "Feed",
        path: "/admin/feeds",
      },
      {
        key: "reports",
        image: report,
        activeImage: activeReport,
        label: "Reports",
        path: "/admin/reports",
      },
      {
        key: 'kiosk',
        image: kiosk,
        activeImage: kioskActive,
        label: 'Kiosk',
        path: '/admin/kiosk',
      },
    ];

    const filteredData = adminMenuList.filter(item =>
      roleList?.includes(item.key),
    );

    setData(filteredData);
  }, []);

  useEffect(() => {
    const moduleName = currentUrl.split('/')[2];

    localStorage.setItem('activeMenu', moduleName);
    setActiveItem(moduleName);
  }, [currentUrl]);

  return (
    <div>
      {data.map((item, index) => (
        <Link to={item.path}>
          <li
            key={index}
            className={` ${activeItem === item.key && 'active'}`}
            onClick={() => handleItemClick(item.key)}>
            <div className="menuItemContainer">
              <img
                src={activeItem === item.key ? item.activeImage : item.image}
                className="menuItemImage"
              />
              <p
                className={` ${activeItem === item.key
                  ? 'activeMenuItemLabel'
                  : 'menuItemLabel'
                  }`}>
                {item.label}
              </p>
            </div>
          </li>
        </Link>
      ))}
    </div>
  );
};

export default AdminMenuList;

// import dashboardImg from "../assets/dashboard.svg";
// import orgImg from "../assets/Org.svg";
// import employeeImg from "../assets/employee.svg";
// import leaveImg from "../assets/leave.svg";
// import attendanceImg from "../assets/attendance.svg";
// import payrollImg from "../assets/payroll.svg";
// import profileImg from "../assets/profile.svg";
// import settingImg from "../assets/setting.svg";
// import jobImg from "../assets/job.svg";

// // how to get array from localstorage
// const roleList = JSON.parse(localStorage.getItem("role_list"));
// // const roleList = localStorage.getItem("role_list")

// // console.log("roleList: ", roleList[0]);

// const adminMenuList = [
//     {
//         key: "dashboard",
//         image: dashboardImg,
//         label: "Dashboard",
//         path: "/dashboard"
//     },
//     {
//         key: "ORG",
//         image: orgImg,
//         label: "Org",
//         path: "/org"
//     },
//     {
//         key: "JOB",
//         image: jobImg,
//         label: "Job",
//         path: "/job/admin-job-list"
//     },
//     {
//         key: "RECRUITMENT",
//         image: jobImg,
//         label: "recruitment",
//         path: "/recruitment"
//     },
//     {
//         key: "employee",
//         image: employeeImg,
//         label: "Employee",
//         path: "/employee"
//     },
//     {
//         key: "leave",
//         image: leaveImg,
//         label: "Leave",
//         path: "/leave/admin"
//     },
//     {
//         key: "ATTENDANCE",
//         image: attendanceImg,
//         label: "Attendance",
//         path: "/attendance/admin/dashboard"
//     },
//     {
//         key: "PAYROLL",
//         image: payrollImg,
//         label: "Payroll",
//         path: "/pay-roll"
//     },
//     {
//         key: "profile",
//         image: profileImg,
//         label: "Profile",
//         path: "/user-profile"
//     },
//     // {
//     //     key: "setting",
//     //     image: settingImg,
//     //     label: "Setting",
//     //     path: "/setting"
//     // }
// ]
// let data = [];
// console.log("a................", adminMenuList?.map((item) => {
//     if (roleList?.includes(item.key)) {
//         data.push(item)
//     }
// }))

// console.log(data);

// export default data;
