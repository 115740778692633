import React, { useEffect, useState } from 'react'
import request from '../../../../utils/axios'
import useScreenSize from '../../../../utils/getScreenSize'
import { Spin, Table } from 'antd'
import InfiniteScroll from 'react-infinite-scroll-component'
import { toast } from 'react-toastify'
import { LoadingOutlined } from '@ant-design/icons'
import back from "../../../../assets/Recruitment/back.svg"
import { useNavigate } from 'react-router-dom'

const BulkAdHocDeduction = () => {
    const navigate = useNavigate()
    const screenSize = useScreenSize()
    const [loading, setLoading] = useState(false)
    const [bulkAdHocDeductionData, setBulkAdHocDeductionData] = useState([])
    const [selectedRow, setSelectedRow] = useState([])
    const [bulkAdHocDeductionPagination, setbulkAdHocDeductionPagination] = useState({
        current: 1,
        pageSize: 15, // Number of items per page
        total: 0,
    })
    const [hasMore, setHasMore] = useState(true)




    const fetchAdHocDeductionData = async (pagination = bulkAdHocDeductionPagination, dataList = []) => {
        const { current, pageSize } = pagination;
        try {
            setLoading(true);
            const response = await request({
                method: "get",
                url: `payroll/admin/adhoc-deduction/details?page=${current}&page_size=${pageSize}`,
            });
            
            console.log(response)
            const newData = response?.pagination_data?.data || [];
            setBulkAdHocDeductionData(current === 1 ? newData : [...dataList, ...newData]);
    
            setbulkAdHocDeductionPagination({
                current: current + 1,
                pageSize,
                total: response?.pagination_data?.total || 0,
            });
    
            setHasMore(response?.pagination_data?.next !== null);
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to fetch data", { position: "bottom-left" });
        } finally {
            setLoading(false);
        }
    };
    


    const bulkAdHocDeductionDataTableColoms = [
        {
            title: "Employee Name",
            dataIndex: "employee_name",
            key: "employee_name",
        },
        {
            title: "Employee Number",
            dataIndex: "employee",
            key: "employee",
        },
        {
            title: "Date",
            dataIndex: "created_at",
            key: "created_at",
        },
        {
            title: "Ad-Hoc Deduction Type",
            dataIndex: "get_adhoc_deductions_display",
            key: "get_adhoc_deductions_display",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Comment",
            dataIndex: "comment",
            key: "comment",
        },
    ]


    //Desktop UI
    const renderDesktopUI = (data) =>{
        return(
            <>
                <Table
                rowSelection={rowSelection}
                columns={bulkAdHocDeductionDataTableColoms}
                dataSource={data}
                pagination={false}
                />
            </>
        )
    }
    
    

    const rowSelection = {
        selectedRowKeys: selectedRow.filter(key =>
            Array.isArray(bulkAdHocDeductionData) &&
            bulkAdHocDeductionData.some(item => item.key === key)
        ),
        onChange: (selectedRowKeys) => {
            const newSelectedRowKeys = [
                ...selectedRow.filter(key =>
                    Array.isArray(bulkAdHocDeductionData) &&
                    !bulkAdHocDeductionData.some(item => item.key === key)
                ),
                ...selectedRowKeys
            ];
            setSelectedRow(newSelectedRowKeys);
        }
    };
    




    const handleBulkDelete = async () => {
        if (selectedRow.length === 0) {
            toast.error("Selection Required For Bulk Delete", { position: "bottom-left" });
            return;
        }
    
        setLoading(true);
    
        try {
            await request({
                method: "delete",
                url: `payroll/admin/bulk-delete-adhoc-deductions`,
                data: {
                    ids: selectedRow,
                },
            });
    
            toast.success("Bulk Action Completed Successfully!", { position: "bottom-left" });
    
            // Reset selected rows and pagination
            setSelectedRow([]);
            setbulkAdHocDeductionPagination({
                current: 1,
                pageSize: 15,
                total: 0,
            });
    
            // Fetch data for the first page
            fetchAdHocDeductionData({
                current: 1,
                pageSize: 15,
            }, []);
    
            setHasMore(true); // Reset infinite scroll to allow further fetching
        } catch (error) {
            console.error("error", error);
            toast.error("Bulk Action Unsuccessful!", { position: "bottom-left" });
        } finally {
            setLoading(false);
        }
    };
    
    
    


    useEffect(() => {
        fetchAdHocDeductionData()
    }, [])


return (
    <div 
        style={{
            overflowY: "auto",
            overflowX: "hidden",
            height: "100%",
            padding: "20px"
        }}
        id='scrollDiv'>
     {
        screenSize<768 ?
            <>
            </>
        :
        <>
            <div>
            <p onClick={() => { navigate('/admin/payroll/18') }} style={{ cursor: "pointer" }}>
                <img src={back} alt="back" />
                <span style={{ paddingLeft: "10px" }}>Back</span>
            </p>
                <div style={{ background: "white", padding: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center", marginBottom:"10px" }}>
                        <p style={{ fontSize: "24px", fontWeight: "500", color: "#161616" }}>Ad Hoc Deduction</p>
                        <button className='commonBtn' onClick={handleBulkDelete}>{loading? <Spin indicator={<LoadingOutlined spin style={{ color: "white" }} />} />:"Delete"}</button>
                    </div>
                    <InfiniteScroll
                        dataLength={bulkAdHocDeductionData?.length || 0}
                        next={() => fetchAdHocDeductionData(bulkAdHocDeductionPagination, bulkAdHocDeductionData)}
                        hasMore={hasMore}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Yay! You have seen it all</b>
                            </p>
                        }
                        loader={
                            <p style={{ textAlign: 'center' }}>
                                <b>Loading...</b>
                            </p>
                        }
                        scrollableTarget="scrollDiv"
                        // className={styles.customScrollbar}
                    >
                        {
                            renderDesktopUI(bulkAdHocDeductionData)
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </>

    }</div>
  )
}

export default BulkAdHocDeduction